<template>
  <v-dialog v-model="dialog" persistent max-width="80%">
    <template v-slot:activator="{ on }">
      <app-add-button v-bind:module="'user'"></app-add-button>
    </template>
    <v-form
      ref="form"
      v-model="isvalid"
      lazy-validation
    >
      <v-card tile>
        <v-toolbar
          flat
          dark
          color="primary"
        >
          <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
          <div class="flex-grow-1"></div>
          <v-btn
            icon
            dark
            @click="close"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.email_add"
                    label="Email"
                >
                    <template v-slot:append>        
                      <v-icon @click="getUser()" :color="hrisToken ? 'primary' : 'gray'" :disabled="!hrisToken"> mdi-magnify </v-icon> 
                    </template></v-text-field>
                </v-flex>
              <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.user_id"
                    :rules="user_idRules"
                    label="User ID"
                    required
                ></v-text-field>
              </v-flex>
              <!-- <v-flex xs12 sm6 md4>
                <v-select
                    v-model="editedItem.sper_user"
                    :items="sperUser"
                    :rules="[v => !!v || 'Please select Option']"
                    label="Super User"
                    data-vv-name="sperUser"
                    required
                ></v-select>
                </v-flex> -->
                <v-flex xs12 sm6 md4>
                <v-select
                    v-model="editedItem.dept_code"
                    :items="dataDepts"
                    data-vv-name="depts"
                    item-text="dept_desc"
                    item-value="dept_code"
                    label="Department Code"
                    readonly
                    @change="getData(this.editedItem.dept_code)"
                    required
                ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <!-- <v-text-field 
                    v-model="editedItem.brnc_code"
                    :counter="4"
                    label="Branch Code"
                    maxlength=4
                    required
                ></v-text-field> -->
                  <v-select
                    v-model="editedItem.brnc_code"
                    :items="branchesItem"
                    data-vv-name="branches"
                    item-text="brnc_name"
                    item-value="brnc_code"
                    readonly
                    label="Branch Code"
                    required
                    v-on:focus="getData()"
                  >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.brnc_code }} - {{ data.item.brnc_name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.brnc_code }} - {{ data.item.brnc_name }}
                  </template>
                  </v-select>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <!-- <v-menu
										:nudge-right="40"
										transition="scale-transition"
										offset-y
										min-width="290px"
                    v-model="start_date"
                  >
                    <template v-slot:activator="{ on }"> -->
                      <v-text-field
                        label="Start Date"
                        v-model="editedItem.start_date"
                        mask="YYYY-MM-DD"
                        readonly
                        placeholder="YYYY-MM-DD">
                      </v-text-field>
                    <!-- </template>
                    <v-date-picker
                      color="primary"
                      readonly
                      v-model="editedItem.start_date"
                    ></v-date-picker>
                  </v-menu> -->
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-menu
										:nudge-right="40"
										transition="scale-transition"
										offset-y
										min-width="290px"
                    v-model="end_date"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="End Date"
                        v-model="editedItem.end_date"
                        v-on="on"
                        mask="YYYY-MM-DD"
                        placeholder="YYYY-MM-DD">
                      </v-text-field>
                    </template>
                    <v-date-picker
                      color="primary"
                      v-model="editedItem.end_date"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field 
                      v-model="editedItem.user_first_name"
                      label="First Name"
                      readonly
                      required
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md4>
                  <v-text-field 
                      v-model="editedItem.user_middle_name"
                      label="Middle Name"
                      readonly
                      required
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md4>
                  <v-text-field 
                      v-model="editedItem.user_last_name"
                      label="Last Name"
                      readonly
                      required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-select
                    v-model="editedItem.role_id"
                    :items="roles"
                    editable
                    item-text="roles"
                    item-value="id"
                    label="Role"
                    :rules="defaultRules"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.jobt_code"
                    :counter="4"
                    label="Job Title Code"
                    :rules="defaultRules"
                    maxlength=4
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-autocomplete
                    class="remove-underline"
                    v-model="editedItem.approver"
                    :items="getOfficers"
                    hide-details
                    label="Approver"
                    :rules="defaultRules"
                    item-text="approver_name"
                    item-value="user_id"
                  ></v-autocomplete>
                </v-flex>
            </v-layout>
            </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" dark @click="save">Save</v-btn>
          <v-btn color="red darken-1" text @click="close">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAddButtonVue from '../../../partials/AppAddButton.vue';
// import AppDatePickerVue from '../../../partials/AppDatePicker.vue';

export default {
	components: {
		'app-add-button': AppAddButtonVue,
		// 'app-dt-picker': AppDatePickerVue
	},
	data: () => ({
		dataDepts: [],
		dataOfficers: [],
		branchesItem: [],
		sectionItem: [],
		nextPageDepts: '',
		nextPageOfficers:'',
		isvalid: false,
		dept_codeRules: [
			v => !!v || 'Department Code is required',
			v => (v && v.length <= 3) || 'Department Code must be 3 characters or less.',
		],
		user_idRules: [
			v => !!v || 'User ID  is required'
		],
		defaultRules: [
			v => !!v || 'Required field'
		],
		start_date: false,
		end_date: false,
		// confirm_password: ''
	}),

	computed: {
		...mapGetters({
			branches: 'branch/branches',
			users: 'user/users',
			sperUser: 'user/sperUser',
			editedItem: 'user/editedItem',
			defaultItem: 'user/defaultItem',
			dialog: 'user/dialog',
			editedIndex: 'user/editedIndex',
			depts: 'dept/depts',
			formTitle: 'user/formTitle',
			isLoading: 'user/isLoading',
			valid: 'user/valid',
			currUser: 'auth/currUser',
			roles: 'roles/roles',
			approvingofficer: 'approvingofficer/approvingofficers',
			hrisToken: 'auth/hrisToken'
		}),
		getOfficers(){
			return this.dataOfficers.filter(e => e.level === 1);
		},
	}, 
	mounted() {
		this.$store.dispatch('branch/getBranches');
		this.$store.dispatch('dept/getDepts');
		this.$store.dispatch('roles/getRoles');
		this.$store.dispatch('approvingofficer/getApprovingOfficers');
	},
	watch: {
		depts: {
			handler() {
				this.getDataDepts();
			}
		},
		approvingofficer: {
			handler() {
				this.getDataApprovingofficer();
			}
		},
	},
	methods: {
		async getDataApprovingofficer(){
			this.dataOfficers = this.approvingofficer.data;
			this.nextPageOfficers = this.approvingofficer.next_page_url;
			let page = 2;

			while (this.nextPageOfficers !== null){
				await this.$store.dispatch('approvingofficer/getDataPages', page).then(response => {
					this.nextPageOfficers = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataOfficers.push(details);
					});
				});
				page++;
			}

		},
		async getDataDepts(){
			this.dataDepts = this.depts.data;
			this.nextPageDepts = this.depts.next_page_url;
			let page = 2;

			while (this.nextPageDepts !== null){
				await this.$store.dispatch('dept/getData', page).then(response => {
					this.nextPageDepts = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataDepts.push(details);
					});
				});
				page++;
			}
			let dept = this.dataDepts.filter(e => e.dept_status === 1);
			this.dataDepts = dept;

		},
		getData(dept_code){
			let dept = this.dataDepts.filter(det => det.dept_code == dept_code);
			let dept_id = dept[0].id;
			if(dept_id == 1 || dept_id == 25 || dept_id == 26 || dept_id == 29){
				this.branchesItem = this.branches.filter(e => e.brnc_stat === 'OPEN');
			} else {
				this.branchesItem = this.branches.filter(det => det.brnc_code === 'HOB' && det.brnc_stat === 'OPEN');
			}
		},
		close () {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('user/setValid',true);
				this.$store.dispatch('user/setDialog',false);
				//this.isvalid = false;
			}, 2000);
		},
      
		async save () {
			this.$store.dispatch('user/setValid',this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			this.editedItem.edit_by = this.currUser.id;
			this.editedItem.sper_user = 0;
			if(this.valid){
				this.$store.dispatch('user/setisLoading',true);
				if (this.editedIndex > -1) {
					await this.$store.dispatch('user/updateUser',this.editedItem);
				} else {
					await this.$store.dispatch('user/saveUser',this.editedItem);
				}
				setTimeout(() => {
					this.close();
				}, 3000);
			}
		},
		getUser(){
			if(this.editedItem.email_add){
				this.editedItem.user_id = '';
				this.editedItem.start_date = '';
				this.editedItem.user_first_name = '';
				this.editedItem.user_middle_name = '';
				this.editedItem.user_last_name = '';
				this.editedItem.dept_code = '';
				this.editedItem.brnc_code = '';
				this.$store.dispatch('user/getUserHris',{data: this.editedItem.email_add, token: this.hrisToken}).then(response => {
					let data = response.data.StatusDescription;
					if(data === 'Sorry! Nothing was found.' ){
						this.$store.commit('app/SET_SNACK_BAR', { 
							'status': true, 
							'message': 'No Data for this Email Address.', 
							'messagetype': 'error' 
						}, { 
							root: true 
						});
					} else {
						let responseData = response.data.body;
						if(responseData.isstatus_name === 'Active'){
							this.editedItem.user_id = responseData.nickname;
							this.editedItem.start_date = responseData.datehired;
							this.editedItem.user_first_name = responseData.firstname;
							this.editedItem.user_middle_name = responseData.middlename;
							this.editedItem.user_last_name = responseData.lastname;

							let dept = this.dataDepts.filter(det => det.dept_desc === responseData.bd_id_name);
							if(dept.length){
								this.getData(dept[0].dept_code);
								this.editedItem.dept_code = dept[0].dept_code;
								this.editedItem.brnc_code = responseData.bbl_id_homebased_code;
							}
						} else {
							this.$store.commit('app/SET_SNACK_BAR', { 
								'status': true, 
								'message': 'Inactive User.', 
								'messagetype': 'error' 
							}, { 
								root: true 
							});
						}
					}
				});
			} else {
				this.$store.commit('app/SET_SNACK_BAR', { 
					'status': true, 
					'message': 'Please fill out Email Address.', 
					'messagetype': 'error' 
				}, { 
					root: true 
				});
			}
		}
	},
};
</script>