<template>
	<div class="home">
		<table-user></table-user>
	</div>
</template>

<script>
import tableUser from '@/components/pages/maint/user/tableUser.vue';

export default {
	components: {
		'table-user': tableUser,
	}
};
</script>

<style>

</style>
