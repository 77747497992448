<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="users.data"
      class="elevation-1"
		dense
		:items-per-page="1000"
		hide-default-footer
		:search="search"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Users</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
		<vue-excel-xlsx
			:data="users.data"
			:columns="columnHeaders"
			:file-name="module_name"
		>
			<v-btn icon alt class="mt-2" color="primary"><v-icon>mdi-arrow-down-bold-circle</v-icon> </v-btn>
		</vue-excel-xlsx>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 3"
				>
					mdi-pencil-off
				</v-icon>
				<v-icon
					color="primary"
					v-else
					class="mr-2"
					small
					@click="editItem(item)"
				>
					edit
				</v-icon>
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 4"
				>
					mdi-delete-off
				</v-icon>
				<v-icon
					small
					v-else
					color="red"
					@click="deleteItem(item)"
				>
					delete
				</v-icon>
			</template>
			
    <template v-slot:body.append>
					<tr>
						<td colspan="8">
							<v-layout>
								<v-spacer></v-spacer>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											:disabled="parseInt(users.current_page)==1"
											@click="changePrevData"
											class="ma-2"
											text
											icon
										>
											<v-icon :disabled="parseInt(users.current_page)==1">mdi-chevron-left</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
								<v-flex md2 style="padding-top:15px;text-align: center">
									<p class="font-weight-light text--primary body-2">
										Page {{users.current_page}} ( {{users.from}} - {{users.to}} )
									</p>
								</v-flex>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											@click="changeNextData"
											class="ma-2"
											:disabled="!users.next_page_url"
											text
											icon
										>
											<v-icon :disabled="!users.next_page_url">mdi-chevron-right</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
							</v-layout>
						</td>
					</tr>
			</template>
    </v-data-table>
    <create-user v-if="role_access > 1" ></create-user>
  </div>
</template>

<script>
import createUser from './createUser.vue';
import { mapGetters } from 'vuex';

export default {
	data(){
		return {
			module_name: 'Users',
			role_access: 1,
			search: ''
		};
	},
	components: {
		'create-user': createUser
	},
	computed: {
		...mapGetters({
			users: 'user/users',
			headers: 'user/headers',
			columnHeaders: 'user/columnHeaders',
			editedItem: 'user/editedItem',
			defaultItem: 'user/defaultItem',
			dialog: 'user/dialog',
			editedIndex: 'user/editedIndex',
			isLoading: 'user/isLoading',
			valid: 'user/valid',
			currUser: 'auth/currUser'
		}),
	},
	mounted () {
		this.$store.dispatch('user/getUsers');

		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
	},
	methods: {
		async editItem (item) {
			this.$store.dispatch('user/setEditedIndex',this.users.data.indexOf(item));
			//this.$store.dispatch('user/setEditedItems',Object.assign({}, item));
			await this.$store.dispatch('user/getDetails', item.id);
			this.$store.dispatch('user/setDialog',true);
			this.$store.dispatch('user/setValid',true);
			this.$store.dispatch('user/setformTitle','Edit Entry');
		},
		deleteItem (item) {
			this.$swal.fire({
				title: 'Delete ' + item.user_last_name + ', ' + item.user_first_name + '?',
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('user/setEditedIndex', this.users.data.indexOf(item));
					this.$store.dispatch('user/deleteUser',item.id);
				}
			});
		},
		changeNextData() {
			var nextPage = this.users.current_page + 1;
			this.$store.dispatch('user/getDataPages', nextPage);
		},

		changePrevData() {
			var prevPage = this.users.current_page - 1;
			this.$store.dispatch('user/getDataPages', prevPage);
		}
	}
};
</script>